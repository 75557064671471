<template>
    <div class="h-alt-hf d-flex flex-column align-items-center justify-content-center text-center">
        <h1 class="page-error color-fusion-500">
            ERROR <span class="text-gradient">404</span>
            <small class="fw-500">
                Something <u>went</u> wrong!
            </small>
        </h1>
        <h3 class="fw-500 mb-5">
            You have experienced a technical error. We apologize.
        </h3>
        <h4>
            We are working hard to correct this issue. Please wait a few moments and try your search again.
            <br>In the meantime, check out whats new on SmartAdmin WebApp:
        </h4>
    </div>
</template>

<script>
    import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";

    export default {
        name: "edutalk-error",
        mounted() {
            this.$store.dispatch(SET_BREADCRUMB, [
                {title: "Quản lý nhân sự", route: 'profile-1'},
                {title: "Danh sách nhân viên"}
            ]);
        },
        computed: {
            backgroundImage() {
                return process.env.BASE_URL + "media/error/bg1.jpg";
            }
        }
    };
</script>
